import Head from 'next/head';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { Login } from '../features/auth/ui';
import { AuthEmptyLayout } from '../features/auth/ui/common/AuthEmptyLayout';
import CarouselLayout from '../features/auth/ui/common/carousel/CarouselLayout';

function LoginPage() {
  const { t } = useTranslation();

  return (
    <AuthEmptyLayout publicTopBar>
      <Head>
        <title>{t('LOGIN_SEO_META_TITLE')}</title>
        <meta name="description" content={t('LOGIN_SEO_META_DESCRIPTION')} />
      </Head>

      <CarouselLayout>
        <Login />
      </CarouselLayout>
    </AuthEmptyLayout>
  );
}

export default LoginPage;
