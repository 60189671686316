export interface CarouselStep {
  imgUrl: string;
  title: string;
  subtitle: string;
}

const CarouselSteps: CarouselStep[] = [
  {
    imgUrl: '/assets/carousel/card.svg',
    title: 'Financia y adelanta tus pagos con Payments',
    subtitle:
      'Programa y paga tus obligaciones con capital propio o accede a financiamiento.',
  },
  {
    imgUrl: '/assets/carousel/proposito.png',
    title: 'Anticipa tus facturas con Financiemiento Directo',
    subtitle: 'Obtén liquidez en menos de 24 horas, con tasas personalizadas.',
  },
  {
    imgUrl: '/assets/carousel/telefono.png',
    title: 'Accede gratis a Xtracker y analiza tus ventas',
    subtitle:
      'Visualiza toda tu información financiera y toma mejores decisiones a futuro.',
  },
];

export default CarouselSteps;
