import React from 'react';
import { Hidden, makeStyles, Theme } from '@material-ui/core';
import { useMobile } from '../../../../home/infra/context/useMobile';

interface UseStylesProps {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>((theme: Theme) => ({
  carouselContainer: {
    width: ({ isMobile }) => (isMobile ? '100%' : '50%'),
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundColor: theme.palette.background.default,
  },
}));

interface BlueSkyBackgroundProps {
  isMobileCarouselEnabled: boolean;
  showChild: boolean;
}

const BlueSkyBackground = function BlueSkyBackground({
  isMobileCarouselEnabled,
  showChild,
}: BlueSkyBackgroundProps) {
  const isMobile = useMobile();
  const classes = useStyles({ isMobile });
  return (
    <>
      {isMobileCarouselEnabled && isMobile && !showChild ? (
        <div className={classes.carouselContainer} />
      ) : null}
      <Hidden only={['sm', 'xs']}>
        <div className={classes.carouselContainer} />
      </Hidden>
    </>
  );
};

export default BlueSkyBackground;
