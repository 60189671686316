import { Container, MuiThemeProvider, makeStyles } from '@material-ui/core';

import { TopbarPrivate, TopbarPublic } from '@features/home/ui/commons/Layout';
import { theme as saasTheme } from './theme/theme';
import { utmTracker } from '../../../libs/utmTracker';
import { usePrefetchHomeHook } from '../../adapters/common/usePrefetchHomeHook';

interface AuthEmptyLayoutProps {
  children: React.ReactNode;
  publicTopBar?: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    paddingTop: 64,
    backgroundColor: theme.palette.common.white,
  },
}));

export function AuthEmptyLayout({
  children,
  publicTopBar,
}: AuthEmptyLayoutProps) {
  const classes = useStyles();
  utmTracker();
  usePrefetchHomeHook();
  const Topbar = publicTopBar ? <TopbarPublic /> : <TopbarPrivate />;

  return (
    <MuiThemeProvider theme={saasTheme}>
      {Topbar}
      <Container disableGutters maxWidth={false} className={classes.container}>
        {children}
      </Container>
    </MuiThemeProvider>
  );
}

AuthEmptyLayout.defaultProps = {
  publicTopBar: false,
};
