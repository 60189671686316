import React from 'react';
import Image from 'next/image';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Spacer } from '../../../../shared';
import { CarouselStep } from './CarouselSteps';
import { Typography } from '../../../../../src/design-system/atoms';

interface CarouselItemProps {
  step: CarouselStep;
}

const CarouselItem = function CarouselItem({ step }: CarouselItemProps) {
  return (
    <Grid item xs={12}>
      <Image
        width={1}
        height={1}
        src={step.imgUrl}
        layout="responsive"
        unoptimized
      />
      <Spacer spacing={2} />
      <Box textAlign="center">
        <Typography variant="h4">
          <Box fontWeight="fontWeightMedium">{step.title}</Box>
        </Typography>
      </Box>
      <Spacer spacing={2} />
      <Box textAlign="center">
        <Typography variant="body1">{step.subtitle}</Typography>
      </Box>
    </Grid>
  );
};

export default CarouselItem;
