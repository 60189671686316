import { FC, ReactElement, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Step,
  Stepper,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { GridCenter, Spacer } from '../../../../shared';
import { useMobile } from '../../../../home/infra/context/useMobile';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: theme.spacing(50),
    padding: theme.spacing(0, 2),
  },
  step: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[400],
  },
  stepActive: {
    width: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    transition: 'width 200ms ease-in-out',
  },
  adjustLeftArrow: {
    paddingLeft: theme.spacing(1),
  },
  iconButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[500],
    borderRadius: 8,
  },
  iconButtonTouchRipple: {
    borderRadius: 8,
  },
}));

interface CarouselProps {
  steps: number;
  onFinish: () => void;
  renderActiveStep?: (index: number) => ReactElement;
}

const Carousel: FC<CarouselProps> = function Carousel({
  steps,
  onFinish,
  renderActiveStep,
}) {
  const classes = useStyles();
  const isMobile = useMobile();
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    if (step === steps - 1) {
      onFinish();
      return;
    }
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    if (step === 0) {
      return;
    }
    setStep(step - 1);
  };

  const listOfSteps = useMemo(
    () => Array.from({ length: steps }, (v, i) => i),
    [steps]
  );

  return (
    <Grid container className={classes.container}>
      {renderActiveStep && renderActiveStep(step)}
      <Spacer spacing={isMobile ? 2 : 4} />
      <Grid item xs={12}>
        <Grid container>
          {!isMobile && (
            <Grid item xs={3}>
              <Box textAlign="right">
                <IconButton
                  size="small"
                  onClick={handlePrevStep}
                  className={classes.iconButton}
                  TouchRippleProps={{
                    classes: { child: classes.iconButtonTouchRipple },
                  }}
                >
                  <ArrowBackIosIcon
                    color="action"
                    fontSize="small"
                    viewBox="-6 0 24 24"
                  />
                </IconButton>
              </Box>
            </Grid>
          )}
          <Grid item xs={isMobile ? 12 : 6}>
            <GridCenter>
              <Stepper
                nonLinear
                alternativeLabel
                connector={null}
                activeStep={step}
              >
                {listOfSteps.map(key => {
                  const isSelected = key === step;
                  return (
                    <Step key={key} expanded={isSelected} active={isSelected}>
                      <div
                        className={clsx(
                          classes.step,
                          isSelected ? classes.stepActive : ''
                        )}
                      />
                    </Step>
                  );
                })}
              </Stepper>
            </GridCenter>
          </Grid>
          {!isMobile && (
            <Grid item xs={3}>
              <IconButton
                size="small"
                onClick={handleNextStep}
                className={classes.iconButton}
                TouchRippleProps={{
                  classes: { child: classes.iconButtonTouchRipple },
                }}
              >
                <ArrowForwardIosIcon fontSize="small" color="action" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Spacer spacing={2} />
      {isMobile && (
        <Grid item xs={12}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={handleNextStep}
          >
            {step === steps - 1 ? 'Empezar' : 'Siguiente'}
          </Button>
        </Grid>
      )}
      <Spacer spacing={2} />
    </Grid>
  );
};

Carousel.defaultProps = {
  renderActiveStep: null,
};

export default Carousel;
